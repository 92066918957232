<template>
  <body>
  <div class="news">
    <!-- <h1>{{ msg }}</h1> --> 
    <div class="main"> 
      <div class="top_fixd">
        <div class="top">
        <div class="logo">
          <img src="@/assets/common/igsoft_logo.png" alt="" />
        </div>
        <div class="funtion">
          <span class="fun" @click="counter1">产品服务</span>
          <span class="fun" @click="counter2">服务案例</span>
          <span class="fun" @click="counter3">合作单位</span>
          <span class="fun" @click="counter4">公司简介</span>
          <span class="fun" @click="counter5">联系我们</span>
        </div> 
      </div> 
      </div>
        <div>
          <!-- <Banner :bannerData="bannerData" :interval="3000"/> -->
        <div class="core">
          <img  class="core" src="@/assets/banner.png" alt="" />
        </div>
        <div class="product1 product" id="productId">
         
          <div class="title_main">
            <div class="title">产品服务</div>
            <!-- 安全为主那部分 -->
            <!-- <div class="maintop">-安全为核心-</div>
            <div class="safety_box">
              <img class="safety" src="@/assets/safety/safety.png" alt="">
            </div> -->
            <div class="sign "> 
              <div class="maintop">-报名系统-</div>
            <div>
              <!-- <div class="interview_style">系统功能</div> -->
              <div class="interview_styleBox">
                
                <div class="registration">
                  <div class="biaoti">
                  <h3>考生报名:</h3>
                </div>
                  <p>1、基本信息、教育经历、工作经历在线填报</p>
                  <p>2、身份证、照片、学历、职称等多附件上传</p> 
                  <p>3、在线缴费、打印报名表、准考证、成绩查询</p>
                  <p>4、多端应用（Web、小程序），邮件/短信/微信等多种沟通方式</p>
                  <p>5、支持各省最新的专业分类代码</p>
                  <p>6、界面、字段 、流程等灵活定制</p>
                </div>
                <div>
                  <img class="sign_styebk" src="@/assets/sign/baoming.png" alt="">
                </div>
               </div>
              </div>
              <div class="interview_styleBox">
                <div>
                  <img class="sign_styezkdw" src="@/assets/sign/zkdw.png" alt="">
                </div>
                <div class="registration">
                  <div class="biaoti">
                  <h3>组考单位:</h3>
                </div>
                  <p>主管部门、组织单位、用人单位三方协同</p>
                  <p>岗位信息采集、主管单位审批、公告审核发布</p>
                  <p>考生资料审核、资格审查</p>
                  <p>实时统计分析、工作进度简报</p>
                  <p>各类信息导出、备份、归档</p>
                </div>
               
              </div>
              <div class="interview_styleBox">
                
                <div class="registration">
                  <div>
                  <h3>考务工作辅助:</h3>
                </div>
                  <p>1、实现大规模、复杂场景下考区、考点、考场资源的自动化管理</p>
                  <p>2、可根据考试科目、顺序、考场资源分布等智能生成准考证</p>
                  <p>3、一键生成签到表、座次表、桌贴等各项考务资料</p>
                </div>
                <div>
                  <img class="sign_styebk" src="@/assets/sign/qdb.png" alt="">
                </div>
              </div>
              <!-- <div class="interview_style">系统特点</div> -->
          <div class="styleSafety">
            <div class="style_safety">
              <div>
                  <h3>安全高效：</h3>
                  <p>人脸识别、数据加密、云端部署，国家信息安全等级保护3级标准。</p>
                </div>
                <div>
                  <h3>功能完备：</h3>
                  <p>组织管理、信息发布、报名审核、考务对接、统计分析、生成报告，功能覆盖全工作流程。</p>
                </div>
                <div>
                  <h3>简单易用：</h3>
                  <p>全流程模块化设计、引导式报名、自动化审核。</p>
                </div>
              
             </div>
             <div class="style_safety">
              <div>
                  <h3>拓展性强：</h3>
                  <p>可量身定制专属网站、部署实施最快1个工作日完成。</p>
                </div>
                <div>
                  <h3>成本最低：</h3>
                  <p>免去服务器、网络、人工、开发、维护等费用。</p>
                </div>
                <div class="style_safetyBox">
                  <h3>兼容性强：</h3>
                  <p>支持多端应用，数据自动同步。</p>
                </div>
             </div>
          </div>
            
           </div>
           <div class="signBox">
            <div class="mainfunction_bigbox">
              <div class="maintop">-线上考试-</div>
              <div>
                <div class="mainfunction_box">
                  <!-- <div class="interview_style">核心功能</div> -->
                  <div class="mainfunction">
                  <div class="before_exa1">
                    <h3 style="text-align: center;">考前管理</h3>
                    <div class="exa_set">
                      <span class="exa_set1">基础设置：</span>
                      <span>考试、分类、时间、及格分数、封面、考前说明。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">规则设置：</span>
                      <span>登录认证规则、考试迟到规则、答题时长规则设置。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">试卷准备：</span>
                      <span>支持客观题、主观题等多类题型；</span>
                      <span>支持文字、图片、表格、公式、音频等批量导入。</span>
                    </div>
                  </div>
                  <div class="before_exa2">
                    <h3 style="text-align: center;">考中管理</h3>
                    <div class="exa_set">
                      <span class="exa_set1">智能监考：</span>
                      <span>人脸识别，公安认证，三路音视频实时监控，客户端环境监测，智能预警，作弊排查，云端巡考，监控大屏鸟瞰实时考场，违规记录存底可追溯。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">人工监考与巡考：</span>
                      <span>与智能监考相结，考中可文字、音视频通话等方式与考生直接交流。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">防泄题作弊：</span>
                      <span>随机抽题，小题乱序，选项乱序，一人一卷，防拷贝，界面水印。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">断点续考：</span>
                      <span>考中异常中断，作答实时保存。</span>
                    </div>
                  </div>
                  <div class="before_exa3">
                    <h3 style="text-align: center;">考后管理</h3>
                    <div class="exa_set">
                      <span class="exa_set1">高效阅卷：</span>
                      <span>客观题自动判分，主观题在线双人对阅，问题卷仲裁提醒，组长回评，评卷轨迹可追溯。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">考试成绩：</span>
                      <span>支持考后自动出分，人工批改判分、成绩导入。</span>
                    </div>
                    <div class="exa_set">
                      <span class="exa_set1">数据统计：</span>
                      <span>参考/缺考/及格人数、成绩排名等多维度信息统计。</span>
                    </div>
                  </div>
                  </div>
                </div>
                <div>
                  <!-- <div class="interview_style">系统优势</div> -->
                  <div class="System_style">
                  <div class="systemBox">
                    <div class="systembgc">
                      <h3>安全：</h3>
                      <p>数据存储、传输等全方位的安全防护架构</p>
                    </div>
                    <div class="systembgc">
                      <h3>高并发：</h3>
                      <p>支持10万人同时在线考试</p>
                    </div>
                    <div class="systembgc">
                      <h3>数据分析：</h3>
                      <p>试题、考生多维度数据统计分析，自动生成相关统计数据</p>
                    </div>
                  </div>
                   <div class="systemBox">
                    <div class="systembgc">
                      <h3>简单易用：</h3>
                      <p>管理端三步发布考试，简单易操作</p>
                    </div>
                    <div class="systembgc">
                      <h3>多端应用：</h3>
                      <p>支持PC端、移动端，满足不同业务场景</p>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
              <div class="maincenter">
                <div class="maintop">-面试系统-</div>
                <div>
                  <!-- 面试流程 -->
                  <div class="int_style">
                    <div class="interview_proces">
                    <h3 class="interview_h3">签到</h3>
                    <p class="interview_p">身份证签到、人证比对签到提高效率；手动签到应对身份证遗漏或损坏等特殊场景；签到进度实时统计查询。</p>
                     </div>
                     <div class="interview_proces">
                    <h3 class="interview_h3">抽签</h3>
                    <p class="interview_p">按单位、职位、试卷类别等抽取面试顺序，一键完成考生、考官的分组抽签，避免人工操作失误，告别繁杂冗长的手工抽签工作。</p>
                     </div>
                     <div class="interview_proces">
                    <h3 class="interview_h3">面试</h3>
                    <p class="interview_p">智能规范的面试流程管理，从候考、备考到面试，借助大屏显示、语音提醒、倒计时、数字签名等多种技术手段，确保面试工作有序推进。</p>
                     </div>
                     <div class="interview_proces">
                    <h3 class="interview_h3">打分</h3>
                    <p class="interview_p">精细控制打分范围和小数位数；打要素分或打总分；整场平衡或职位段平衡；数字签名及线上确认，满足不同打分形式的需要。</p>
                     </div>
                     <div class="interview_proces">
                    <h3 class="interview_h3">候分</h3>
                    <p class="interview_p">考生出分动态提醒，刷身份证领取成绩单，避免领错，领取进度实时统计。</p>
                     </div>
                    </div>
                  <!-- 面试形式 -->
                  <div  class="formBox">
                  <img class="interview_form" src="@/assets/interview/interview_form/jghms.png" alt="">
                  <img class="interview_form" src="@/assets/interview/interview_form/wldxztl.png" alt="">
                  <img class="interview_form" src="@/assets/interview/interview_form/sj.png" alt="">
                  <img class="interview_form" src="@/assets/interview/interview_form/mncz.png" alt="">
                  </div>
                  <!-- <div class="interview_style">实施方式</div> -->
                  <div class="interview_f">
                    <img class="interview_mode" src="@/assets/interview/interview_mode/xianxia1.png" alt="">
                  <img class="interview_mode" src="@/assets/interview/interview_mode/xianshang2.png" alt="">
                  <img class="interview_mode" src="@/assets/interview/interview_mode/yuancheng3.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
        <div class="company-style">
          <div class="title">公司优势</div>
          <div class="company-font">
                    <p class="font">
            一、严密的安全保障体系 
           </p>
           <p>
            先进的云存储、网络传输等技术手段，完善的权限控制规则，避免数据泄露，确保数据安全。
           </p>
           <p class="font">
            二、精准的产品研发方向 
           </p>
           <p>
            专注于人事考试领域，依托多年人事考试服务经验，结合实际业务需要，自主研发。
           </p>
           <p class="font">
            三、完善的技术服务体系 
           </p>
           <p>
            专业的产品、服务团队和规范，提供从报名、笔试、面试到心理测评等全流程一站式系统服务。
           </p>
           <p class="font">
            四、丰富的项目实施经验 
           </p>
           <p>
            与各地考试中心、人才市场、以及第三方人才测评机构合作，服务涉及各级政府机关，大型企事业单位。
           </p>
        </div>
        </div>    
        
 
        <div class="propic_main product2" id="productId2">
          <div class="title">服务案例</div>
         <div class="propic">
          <img  class="propic_jpg"  src="@/assets/propic/pro2.jpg" alt="" />
          <img  class="propic_jpg"  src="@/assets/propic/pro6.jpg" alt="" />
          <img  class="propic_jpg"  src="@/assets/propic/pro4.jpg" alt="" />
         </div>
          <div class="propic">
          <img  class="propic_jpg"  src="@/assets/propic/2.jpg" alt="" />
          <img  class="propic_jpg"  src="@/assets/propic/3.jpg" alt="" />
          <img  class="propic_jpg" src="@/assets/propic/1.jpg" alt="" />
         </div>
         <div class="font">
          <p>典型项目经验 </p>
          <p>公务员：广东、湖南、浙江、甘肃等省公务员招录面试</p>
          <p>事业单位：文旅、卫健、人社等部分国务院组成部门所属事业单位公开招聘；北京、河北、广东、湖南、浙江、甘肃、云南等10多个省人社、教育、卫生等主管部门组织的事业单位公开招聘</p>
          <p>部队文职：近年中国人民解放军驻京及部分地区文职人员公开招聘</p>
          <p>高校工作人员：暨南大学、广东工业大学、华南师范大学等全国30多所院校行政、教辅等公开招聘</p>
         </div>
        </div>
      
        <div class="title_logo product3" id="productId3">
          <div class="title">合作单位</div>
          <div class="logo-style">
            <img  class="unit_jpg1" src="@/assets/unit/guangdong.png" alt="" />
            <img  class="unit_jpg1" src="@/assets/unit/zhejiang.png" alt="" />
            <img  class="unit_jpg1" src="@/assets/unit/hunan.png" alt="" />
            <img  class="unit_jpg1" src="@/assets/unit/xian.png" alt="" />
            <img  class="unit_jpg4" src="@/assets/unit/tiyuju.png" alt="" />

          </div>
          <div class="logo-style">
            <img  class="unit_jpg" src="@/assets/unit/honghai.png" alt="" />
            <img  class="unit_jpg" src="@/assets/unit/baidu.png" alt="" />
            <img  class="unit_jpg" src="@/assets/unit/alibaba.png" alt="" />
            <img  class="unit_jpg2" src="@/assets/unit/dianxin.png" alt="" />
            <img  class="unit_jpg3" src="@/assets/unit/liepin.png" alt="" />
          </div>
        </div>
        <div class="company product4" id="productId4" >
        <div class="title">公司简介</div>
         <div class="company-text">
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智政科创（北京）科技发展有限公司从成立之初便扎根于人事考试专业技术服务领域，产品与服务齐头并进、相辅相成，公司业务实现跨越式发展。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多年人事考试技术服务工作的历练，使我们积累了丰富的考试工作实施经验，对人事考试业务有了深入的理解和掌握。并在此基础上不断创新，寻找优化现有业务流程，提高工作效率、增强安全保密效果的技术解决方案，借助于先进的计算机应用与网络技术，自主研发了支持人事考试各业务环节的软硬件产品体系。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;依托于自主研发的技术产品，经验丰富的实施团队，以及科学完善的服务体系，为全国各地各级机关、事业单位、国企、高校、医院，以及各类命题机构、考试机构、人才服务机构等各类人事考试工作保驾护航，取得了优异的成果，得到了用户的一致好评。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;扎根于人才评价业务领域的我们，愿为建设公开、公平、公正、高效、科学的人才评价环境尽自己的绵薄之力。
          </p>
          <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的理念和初衷:专注于人才评价业务,提供最好的产品和服务。</p>
         </div>
        </div>
       
      </div>
      <div class="bottom product5" id="productId5">
        <!-- <img class="bottom-bgc" src="@/assets/底部1.png" alt="" /> -->
        <div class="bottom-bgc">
          <p>
            公司名称：智政科创（北京）科技发展有限公司
          </p>
          <p>
            邮政编码：100029
          </p>
          <p>
            联系方式：010 - 53679653  /  13521498643
          </p>
          <p>
            公司地址：北京市朝阳区芍药居38号楼1016室
          </p>
        </div>
        <div class="bottom-2019">
          © 2019 智政科创（北京）科技发展有限公司 京ICP备19036658号
        </div>
      </div>       
    </div> 
 
</body>
</template>

<script>
export default {
  name: 'WebNews',
  props: {
    msg: String
  },


  methods: {
    counter1() {  //counter1是绑定的点击事件名称
        const returnEle = document.querySelector("#productId");  //productId是将要跳转区域的id
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
        document.querySelector("counter1").scrollIntoView(true); //这里的counter1是将要返回地方的id
    },
    counter2() {  //counter1是绑定的点击事件名称
        const returnEle = document.querySelector("#productId2");  //productId是将要跳转区域的id
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
        document.querySelector("counter2").scrollIntoView(true); 
    },
    counter3() {  
        const returnEle = document.querySelector("#productId3"); 
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
        document.querySelector("counter3").scrollIntoView(true); 
    },
    counter4() {  
        const returnEle = document.querySelector("#productId4"); 
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
        document.querySelector("counter4").scrollIntoView(true);
    },
    counter5() {  
        const returnEle = document.querySelector("#productId5");  
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
        document.querySelector("counter5").scrollIntoView(true); 
    },
      
  }
    

}
</script>

<style scoped>
body {
  margin: 0px;
  /* text-align:center; */
}
/* 最外部盒子 */
.news{
  /* margin: auto ; */

  position: fixed;
  /* z-index: 10 !important; */
  background-position: center;
  /* height: 100%; */
  width: 100%;
  /* background-size: cover; */
  top: 0;
  left: 0;
  height: calc(100%); 
  overflow-y:scroll;
  overflow-x:hidden;
  background-color: #F6F9FE;

}
/* 主体 */
.main{
  /* width: 1200px; */
  /* background-color: pink; */
  margin: auto;

}
/* 头部导航栏 */
.top_fixd{
  position: fixed;
  top: 0;
  /* margin: auto; */
  background-position: center;
  width: 100%;
  top: 0;
  left: 0;
  background-color:   #F6F9FE; 
}
.top{
  display: flex;
  width: 1200px;
 margin: auto;
  height: 76px; 
  align-items: center;

}
.logo {
  flex: 1;
}
.logo img {
  margin-left:40px ;
  width: 180px;
  height: 40px;
}
.funtion{
  margin-left: 180px;
  display: flex;
  width: 880px;
  font-size: 22px;
}

.fun{
  flex: 1;
  /* 小手 */
  cursor: pointer 

}
/* 核心优势企业banner */
.core{
  /* height: 640px; */
  margin-top: 38px;
  margin-bottom: 50px;
  background-position: center;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100vh - 64px ); 
}
/* 中部 */
.product1{
  margin-top: 40px;
}
/* 标题 */
.title{
  margin:48px auto auto;
  width: 1200px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 32px;
  font-family: '微软雅黑';
  color: #0d62ae;
  font-weight: bold;
}
/* 产品服务 */
.title_main{
  /* width: 1200px; */
  height: 4560px;
  /* background-position: center; */
  /* width: 100%; */
  /* top: 0;
  left: 0; */
  /* background-color: pink; */
  /* height: calc(100vh - 64px );  */
  /* margin: auto; */

}


.maintop{
  /* display: flex; */
  width: 1200px;
  margin: 20px auto;
  font-size: 26px;
  color: #0d62ae;
  font-weight: bold;
  text-align: center;

}
/* 安全为主 */
.safety_box{
  width: 1200px;
  margin: auto;
  text-align: center;

}
.safety{
  width: 720px;
  height: 300px;
  /* margin: auto; */
}
.maincenter{
  margin: auto;
  width: 1200px; 
  height: 800px;
  /* background-color: #fff; */
  padding-top: 20px;
}
/* 报名系统 */
.signBox{
  background-color: #EDF2F6;

}
.sign{
  width: 1200px;
  margin: auto;
  margin-bottom:60px ;
  /* text-align: center; */
}
.registration{
  width: 500px;
  height: 330px;
  padding: 28px 30px;
  background-color: #fff;
  margin: 30px;
  border-radius: 6%;
}
.biaoti{
 /* padding-left: 40px; */
 /* margin-right: 10px; */
 width: 140px;
}
.styleSafety{
  display: flex;
  width: 1200px;
  background-color: #fff;
  border: 10px solid #EDF2F6;
  border-radius: 10px;
}
.style_safety{
  width: 600px;
  margin: 20px;
  /* padding-left: 100px; */
}
.sign_styebk{
  width: 500px;
  height:330px;
  background-color: #fff;
  margin: 30px;
  border-radius: 6%;
  padding: 28px 30px;
}
.sign_styezkdw{
  width: 500px;
  height:330px;
  background-color: #fff;
  margin: 30px;
  border-radius: 6%;
  padding: 28px 12px;
}
.sign_stlyeqdb{
  width: 460px;
  height: 300px;
}
/* 产品分类 */
/* .button{
  flex: 1;
  width: 200px;
  height: 60px;
  border:2px solid #5d9ad7;
  background-color: #D2E2F2;
  border-radius:6px;
  margin: 20px 80px 50px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
} */
/* 线上考试 */
.mainfunction_bigbox{
  width: 1200px;
  /* height: 600px; */
  margin: auto;
  padding-top: 20px;
  padding-bottom: 60px;
  /* background-color: pink; */
}
.systembgc{
  /* width: 300px; */
  height: 120px;
  background: url(../assets/libg.png)no-repeat 0 0;
  background-size: 110px;
  padding: 12px 0 0 58px;
  /* margin-left: 30px; */
  /* margin: auto; */
}
.systemBox{
  width: 600px;
  padding-left: 130px;
}
.mainfunction{
  display:flex;
  /* text-align: center; */

}
/* 考试管理 */
.before_exa1{
  width: 300px;
  height: 348px;
  background-color: #c5abc0;
  padding: 16px 10px 16px 18px;
  margin: 40px;
  border-radius: 5%;
  line-height: 22px;
}
.before_exa2{
  width: 300px;
  height: 348px;
  background-color: #c8d7b2;
  padding: 16px 10px 16px 18px;
  margin: 40px;
  border-radius: 5%;
  line-height: 22px;
}
.before_exa3{
  width: 300px;
  height: 348px;
  background-color: #93b4a3;
  padding: 16px 10px 16px 18px;
  margin: 40px;
  border-radius: 5%;
  line-height: 22px;
}
.mainfunction_box{
  /* width: 1200px; */
  /* height: 300px; */
}
.System_style{
  /* margin-left: 60px; */
  display: flex;
  margin-top: 20px;
  width: 1180px;
  /* height: 460px; */
  background-color: #fff;
  padding: 60px 0;
  border: 18px solid #F6F9FE;
}
.exa_set{
  /* display: inline-block; */
  margin: 10px 0;
}
.exa_set1{
font-weight: bold;
}
.interview_proces{
  width: 212px;
  height: 280px;
  margin: 20px 14px 60px;
  background-color: #D2E2F2;
  border-radius: 3%;
  letter-spacing:2px;
  /* text-align: center; */
  padding-left:8px ;
}
.interview_h3{
  text-align: center;
  padding-top: 10px;
}
.interview_p{
  /* padding: 0 10px ; */
  line-height: 22px;
}
/* 报名系统 - 系统功能 */
.interview_styleBox{
display: flex;
margin-bottom:40px;
}
.interview_style{
  font-size: 22px;
  font-family: '微软雅黑';
  color: #0d62ae;
  font-weight: bold;
}
/* 面试形式 */
.int_style{
  width: 1200px;
  margin-top:50px;
  /* text-align: center; */
  /* background-color: #fff; */
  display: flex;

}
/* 面试形式 */
.formBox{
  /* width: 1200px; */
  margin:auto;
  text-align: center;
  background-color: #d4dae0;
  padding-top:50px ;
  border-radius: 20px;
}

/* 实施方式 */
.interview_f{
  /* width: 1200px; */
  margin:50px auto ;
  text-align: center;
  background-color: #D2E2F2;
  padding-top:50px ;
  border-radius: 20px;
}
.interview_form{
  width: 150px;
  height: 230px;
  margin: 20px 60px 60px;
   /* background-color: #fff; */
  /*border-radius: 3%; */
  /* float:left; */
}
.interview_mode{
  width: 140px;
  height: 200px;
  margin: 10px 80px 60px;
}
/* 公司优势 */
.company-style{
  background-position: center;
  width: 100%;
  top: 0;
  left: 0;
  /* height: calc(100vh - 64px );  */
  background-color: #EDF2F6;
  padding-top: 20px;
}
.company-font{
  margin: auto;
  /* margin-bottom: 50px; */
  /* padding-top: 30px; */
  width: 1120px;
  height: 400px;
  padding-left:40px ;
  padding-right:40px ;
  /* background-color: #EDF2F6; */
  font-size: 16px;
}
/* 合作单位_最外层 */
.title_logo{
  /* width: 1200px; */
  background-position: center;
  width: 100%;
  top: 0;
  left: 0;
  /* height: calc(100vh - 64px );  */
  height: 590px;
  /* background-color: #C5DFF2; */
  background-color: #EDF2F6;
  padding-top: 20px;

}
/* 上下两层外边大盒子 */
.logo-style{
  width: 1200px;
  margin: auto;
}
/* 合作单位-logo 每一个 */
.unit_jpg1{
  width: 130px;
  height: 30px;
  margin: 60px 14px;
  background-color:#D2E2F2;
  padding: 38px;
}
.unit_jpg{
  width: 130px;
  height: 30px;
  margin: 14px;
  background-color: #D2E2F2;
  padding: 38px;
}
.unit_jpg2{
  width: 130px;
  height: 38px;
  margin: 14px;
  background-color: #D2E2F2;
  padding: 34px 38px;
}
.unit_jpg3{
  width: 150px;
  height: 62px;
  margin: 14px;
  background-color: #D2E2F2;
  padding: 22px 30px;
}
.unit_jpg4{
  width: 130px;
  height: 30px;
  margin: 60px 14px;
  background-color:#D2E2F2;
  padding: 40px 48px 36px 30px ;

}
/* 公司介绍 */
.company{
  height: 640px;
  font-size: 18px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top:30px ;
}
.company-text{
  width: 1200px;
  margin: auto;
  letter-spacing:4px;
  line-height: 32px;
}

/* 底部导航栏 */
.bottom{
  background:url('~@/assets/dibu.png');
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 400px;
  padding: 20px;
}
.bottom-bgc{
  width: 1200px;
  font-size: 20px;
  margin: auto;
}
.bottom-2019{
  width: 1200px;
  margin-top: 200px;
  margin: 200px auto auto auto  ; 
  text-align: center;
}
.font{
  margin: auto;
  width: 1200px;
  font-size: 20px;
  font-weight:bold;
}


/* 项目经验 */
.propic_main{
  /* display: flex; */
  /* width: 1200px; */
  height: 980px;
  background-position: center;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 20px;

}
.propic{
  /* display: flex; */
  width: 1200px;
  /* height: 400px; */
  margin: auto;
}
.propic_jpg{
  /* flex: 1; */
  margin: 24px 20px;
  width: 360px;
  height: 220px;
}


</style>
