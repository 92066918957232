<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Mobile from './components/mobile.vue'
import Pc from './components/pc.vue'

export default {
  name: 'App',
  components: {
    Mobile,
    Pc
  },
  mounted(){
    if (this._isMobile()) {
      // 手机端
      this.$router.replace("/mobile");
    } else {
      // pc端
      this.$router.replace("/");
    }
  },
  methods:{
  _isMobile() {
      let flag = navigator.userAgent.match(
     /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    }
}
}
</script>

<style>

</style>

