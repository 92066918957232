import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export default new Router({
  mode:"history",
  routes: [
    // pc端
    {
      path: '/',
      name: 'pc',
      component: ()=>import("@/components/pc.vue"),
    },
    // 手机端
    {
      path: '/mobile',
      name: 'mobile',
      component: ()=>import("@/components/mobile.vue"),
    },
  ]
})